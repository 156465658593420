import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import styles from './latest-documents.module.scss'

export default () => (
  <div>
    <StaticQuery
      query={graphql`
      query LatestDocumentsQuery {
        allMarkdownRemark(
          sort: { fields: frontmatter___date, order: [DESC] }
          filter: {
            frontmatter: {
              latest: {
                eq: true
              }
            }
          }
        ) {
          totalCount
          edges {
            node {
              id
              frontmatter {
                date
                title
                file
                featured
                latest
              }
            }
          }
        }
      }
    `}
      render={data => (
        <section className={styles.section}>
          <div className="o-container">
            <div className={styles.content}>
              <h3 className={styles.heading}>Latest documents</h3>
              <ul className={styles.list}>
                {data.allMarkdownRemark.edges.map(({ node }) => (
                  <li
                    className={styles.item}
                    key={node.id}
                  >
                    <a
                    className={styles.link}
                      href={node.frontmatter.file}
                    >
                    {node.frontmatter.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      )}
    />
  </div>
)