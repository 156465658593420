import React from 'react'
import About from 'pages/home/_about'
import AnimateHeight from 'react-animate-height'
import Header from 'components/header'
import Latest from 'pages/home/_latest'
import LatestDocuments from  'pages/home/_latest_documents'
import Layout from 'layouts/layout'

import styles from 'pages/home/home.module.scss'

class Home extends React.Component {

  constructor(props) {
    super(props)
    this.latestNews = React.createRef()
    this.state = {
      latestNewsHeight: 0,
      isLatestNewsVisible: false
    }
    this.handleAccordionToggle = this.handleAccordionToggle.bind(this)
  }

  handleAccordionToggle() {
    const { latestNewsHeight, isLatestNewsVisible } = this.state
    this.setState({
      latestNewsHeight: latestNewsHeight === 0 ? 'auto' : 0,
      isLatestNewsVisible: !isLatestNewsVisible
    })

    if (latestNewsHeight === 0) {
      this.scrollToLatestNews()
    }
  }

  scrollToLatestNews() {
    const latestNews = this.latestNews.current
    setTimeout(function() {
      latestNews.scrollIntoView({
        behavior: 'smooth'
      })
    }, 200)
  }

  render() {
    const {latestNewsHeight, isLatestNewsVisible} = this.state
    const latestNews = this.latestNews

    return (
      <div className="page-dark">
        <Layout>
          <main className={styles.main}>
            <div className={styles.background} />
            <div className={styles.container}>
              <Header layout="standard" />
              <section className={styles.titleBlock}>
                <h1 className={styles.title}>
                  Income and growth
                </h1>
              </section>
              <LatestDocuments></LatestDocuments>
              <About
                button={ isLatestNewsVisible ? 'latest-news-visible' : '' }
                onAccordionToggle={this.handleAccordionToggle}
              />
            </div>

            <div ref={latestNews}>
              <AnimateHeight
                duration={ 500 }
                height={ latestNewsHeight }
              >
                <Latest></Latest>
              </AnimateHeight>
            </div>
          </main>
        </Layout>
      </div>
    )
  }
}

export default Home
