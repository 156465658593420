import React from 'react'
import Chevron from 'svg/chevron'
import TextLink from 'components/text-link'

import styles from './home.module.scss'

class About extends React.Component {

  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.props.onAccordionToggle();
  }

  render() {
    const { button } = this.props
    return (
      <section className={`o-container py-medium ${styles.overlay}`}>
        <p className={styles.overlayIntro}>
          Helios Underwriting is building a portfolio of Lloyd’s capacity made up of the better syndicates at Lloyd’s. This portfolio offers investors income which is uncorrelated to equity market cycles.
        </p>

        <div className={`${styles.grid} ${styles.gridAbout}`}>
          <div className={styles.gridItem}>
            <h3 className="h3 u-flex-1">About Helios Underwriting</h3>
            <TextLink color="terracotta" url="/about-helios-underwriting/overview">
              <span className="u-bold">How our model works</span>
            </TextLink>
          </div>

          <div className={styles.gridItem}>
            <h3 className="h3 u-flex-1">Michael Wade talks to The Insurer TV</h3>
            <TextLink color="terracotta" url="/leading-voices-michael-wade">
              <span className="u-bold">Watch the video</span>
            </TextLink>
          </div>

          <div className={styles.gridItem}>
            <h3 className="h3 u-flex-1">The investment case</h3>
            <TextLink color="terracotta" url="/investors/the-investment-case">
              <span className="u-bold">Why invest in Helios?</span>
            </TextLink>
          </div>

        </div>

        <button
          className={`c-button c-button--white ${styles.button}`}
          onClick={this.handleClick}
        >
          <div className="mr-5">
            {button ? "Hide updates" : "Latest updates"}
          </div>
          <Chevron scaleY={button ? "-1" : "1"} width="11" height="20" />
        </button>

      </section>
    )
  }
}

export default About